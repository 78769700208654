import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../../service/balance.service";

import {
  ConverMoeny,
} from "../../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login"
}

interface Props {
  user : any
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  balance: number;
  point : number
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      point : 0,
    };
  }


  
  handleChangeToBalance = () => {
    if(this.state.balance <= 0 ){
        confirmAlert({
            title: "벨런스",
            message: "벨런스를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "벨런스",
        message: "보유머니를 입금 하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {

                this.balanceService.user_balance_to_point(this.state.balance).then((data: any) => {
                    if (data.status === "success") {
                      confirmAlert({
                        title: "벨런스",
                        message: "벨런스를 금고머니로 변경하였습니다.",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              window.location.reload()
                            },
                          },
                        ],
                      });
            
                    }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };

  handleChangeToPoint = () => {
    if( this.state.point <= 0 ){
        confirmAlert({
            title: "금고머니",
            message: "금고머니를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "금고머니",
        message: "금고머니를 출금하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {
                this.balanceService.user_point_to_money(this.state.point).then((data: any) => {
                    if (data.status === "success") {
                        confirmAlert({
                            title: "금고머니",
                            message: "금고머니를 벨런스로 변경하였습니다.",
                            buttons: [
                              {
                                label: "확인",
                                onClick: () => {
                                  window.location.reload()
                                },
                              },
                            ],
                          });
                  }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };



  render() {

    console.log(this.props.user)
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none"
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
          
          <div>


<div className="modal-app show">
		<button className="modal-close-btn modal-close" data-dismiss="modal" onClick={() => {
                    this.props.handleClose();
                  }}></button>
		<a href="/" className="diamond-logo">
		   <img className="panel" src="/new/images/panel.png"/>
		   <img className="dias-lg" src="/new/images/diamond-lg.png"/>
		   <div className="dias-sm">
			  <img className="diamond-img" src="/new/images/diamond-sm.png"/>
			  <img className="glow" src="/new/images/glow.png"/>
		   </div>
		   <img className="diamond" src="/new/images/diamond.png"/>
		   <img className="slot" src="/new/images/slot.png"/>
		</a>
		<div className="modal-menu-side">
    <button className="deposit-link" onClick={() => {this.props.handleChange(popupView.deposit); }}>
		   <i className="fas fa-coins icon" aria-hidden="true"></i>
		   <span className="text">입금신청</span>
		   </button>
		   <button className="withdraw-link" onClick={() => {this.props.handleChange(popupView.withdraw); }}>
		   <i className="fas fa-university icon" aria-hidden="true"></i>
		   <span className="text">출금신청</span>
		   </button>
		   <button className="event-link" onClick={() => {this.props.handleChange(popupView.point); }}>
		   <i className="fas fa-gem icon" aria-hidden="true"></i>
		   <span className="text">금고</span>
		   </button>
		   <button className="notice-link" onClick={() => {this.props.handleChange(popupView.help); }}>
		   <i className="fas fa-info icon" aria-hidden="true"></i>
		   <span className="text">고객센터</span>
		   </button>
		   <button className="myPage-link" onClick={() => {this.props.handleChange(popupView.user); }}>
		   <i className="fas fa-user icon" aria-hidden="true"></i>
		   <span className="text">마이페이지</span>
		   </button>
       <button className="notice-link" onClick={() => {this.props.handleChange(popupView.notice); }}>
		   <i className="fas fa-crown icon" aria-hidden="true"></i>
		   <span className="text">공지사항</span>
		   </button>
		</div>
	 </div>

	 <div className="modal loginModal subpage-modal fade show" role="dialog" data-backdrop="static" aria-modal="true" style={{paddingRight:'17px',display:'block'}}>
		<div className="modal-dialog modal-dialog-centered">
		   <div className="modal-content">
			  <div className="row">
				 <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pr-lg-5 px-3">
					<div className="modal-title">
					   <h6 className="title mb-0">메뉴</h6>
					   <h6 className="sub ml-2 mb-0">MENU</h6>
					</div>
					<div className="modal-menu">
					   <button className="" onClick={() => {this.props.handleChange(popupView.deposit); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>입금신청</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.withdraw); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>출금신청</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="active">
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>금고</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.help); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>고객센터</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.user); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>마이페이지</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.notice); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>공지사항</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
					</div>
				 </div>
				 <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
					<div className="modal-body">
					   <div className="modal-title">
						  <h6 className="title mb-0">금고</h6>
						  <h6 className="sub ml-2 mb-0">BANK</h6>
					   </div>
            
             <div className="form-container">
              <div className="form-group">
                <div className="input-container">
                  <div className="infos">
                  {
                    this.props.user && 
                    <span >금고 보유금 : {ConverMoeny(this.props.user.point)}</span>
                  }
                  </div>
                </div>
              </div>
            </div>
            
            
            <div className="form-container">
              <div className="form-group">
                <div className="labels">
                    <span>금고입금</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    <input className="form-control parsley-success" value={this.state.balance} onChange={(e) => this.setState({balance: Number(e.target.value),})} />
                  </div>
                </div>
              </div>
            </div>    

            <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={this.handleChangeToBalance}>입금하기</button>
            </div> 

            <div className="form-container">
              <div className="form-group">
                <div className="labels">
                    <span>금고출금</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    <input className="form-control parsley-success" value={this.state.point} onChange={(e) => this.setState({point: Number(e.target.value),})} />
                  </div>
                </div>
              </div>
            </div>   
            
            <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={this.handleChangeToPoint}>출금하기</button>
            </div> 

					</div>
				 </div>
			  </div>
		   </div>
		</div>
	 </div>


   <div className="modal-backdrop fade show"></div>

          </div>

        )}
      </Popup>
    );
  }
}
