import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";

import { UserService } from "../../service/user.service";

import { ConvertDate, HelpStatus, ConvertDate2 } from "../../utility/help";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login"
}

const CustomTableCell = styled(TableCell)`
  color: white;
  padding: 4px;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService()

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            답변
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id)
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell>
        <CustomTableCell align="center"             onClick={() => {
              service.do_help_message_read(row._id)
              setOpen(!open)
            }}
>
          {row.title}
        </CustomTableCell>
        <CustomTableCell align="center">
          {ConvertDate(row.regDate)}
        </CustomTableCell>
        <CustomTableCell align="center">
          {HelpStatus(row.status)}
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  helps: any;
  mode: string;

  title: string;
  contents: string;
}

export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = { helps: [], mode: helpView.view, title: "", contents: "" };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  // handleGetHelpList = () => {
  //   this.userService.get_user_notices().then((data: any) => {
  //     if (data.status === "success") {
  //       this.setState({ notices: data.notices });
  //     }
  //   });
  // };

  handleSaveHelp = (title: string, contents: string) => {
    
    if(title == '' ){
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return 
    }
    if(contents == ''){
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return 
    }
    
    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    this.props.handleClose();
  };

  render() {
    let helps = this.state.helps;

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <div className="form-container">
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              style={{ backgroundColor: "#484848" }}
            >
              <TableHead>
                <TableRow>
                  <CustomTableCell align="center"></CustomTableCell>
                  <CustomTableCell align="center">제 목</CustomTableCell>
                  <CustomTableCell align="center">작성일</CustomTableCell>
                  <CustomTableCell align="center">상태</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helps.map((row: any) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={() => {
                    this.setState({ mode: helpView.write });
                  }}>글쓰기</button>
          </div> 

          
        </div>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <div>

          <div className="form-container">
          <div className="form-group">
                <div className="labels">
                    <span>제목</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                  <input
                      name="textfield2"
                      type="text"
                      className="form-control parsley-success"
                      onChange={(e) =>
                        this.setState({
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
          </div>

          <div className="form-group">
                <div className="labels">
                    <span>내용</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    <textarea
                      className="form-control parsley-success"
                      style={{color:'#000'}}
                      rows={10}
                      onChange={(e) =>
                        this.setState({
                          contents: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn-style" onClick={() => {this.setState({ mode: helpView.view });}}>뒤로가기</button>
          <button type="submit" className="btn-style" onClick={() => {this.handleSaveHelp(this.state.title,this.state.contents);}}>저장하기</button>
        </div>
      </div>

      );
    };
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none"
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
          
          <div>


<div className="modal-app show">
		<button className="modal-close-btn modal-close" data-dismiss="modal" onClick={() => {
                    this.props.handleClose();
                  }}></button>
		<a href="/" className="diamond-logo">
		   <img className="panel" src="/new/images/panel.png"/>
		   <img className="dias-lg" src="/new/images/diamond-lg.png"/>
		   <div className="dias-sm">
			  <img className="diamond-img" src="/new/images/diamond-sm.png"/>
			  <img className="glow" src="/new/images/glow.png"/>
		   </div>
		   <img className="diamond" src="/new/images/diamond.png"/>
		   <img className="slot" src="/new/images/slot.png"/>
		</a>
		<div className="modal-menu-side">
    <button className="deposit-link" onClick={() => {this.props.handleChange(popupView.deposit); }}>
		   <i className="fas fa-coins icon" aria-hidden="true"></i>
		   <span className="text">입금신청</span>
		   </button>
		   <button className="withdraw-link" onClick={() => {this.props.handleChange(popupView.withdraw); }}>
		   <i className="fas fa-university icon" aria-hidden="true"></i>
		   <span className="text">출금신청</span>
		   </button>
		   <button className="event-link" onClick={() => {this.props.handleChange(popupView.point); }}>
		   <i className="fas fa-gem icon" aria-hidden="true"></i>
		   <span className="text">금고</span>
		   </button>
		   <button className="notice-link" onClick={() => {this.props.handleChange(popupView.help); }}>
		   <i className="fas fa-info icon" aria-hidden="true"></i>
		   <span className="text">고객센터</span>
		   </button>
		   <button className="myPage-link" onClick={() => {this.props.handleChange(popupView.user); }}>
		   <i className="fas fa-user icon" aria-hidden="true"></i>
		   <span className="text">마이페이지</span>
		   </button>
       <button className="notice-link" onClick={() => {this.props.handleChange(popupView.notice); }}>
		   <i className="fas fa-crown icon" aria-hidden="true"></i>
		   <span className="text">공지사항</span>
		   </button>
		</div>
	 </div>

	 <div className="modal loginModal subpage-modal fade show" role="dialog" data-backdrop="static" aria-modal="true" style={{paddingRight:'17px',display:'block'}}>
		<div className="modal-dialog modal-dialog-centered">
		   <div className="modal-content">
			  <div className="row">
				 <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pr-lg-5 px-3">
					<div className="modal-title">
					   <h6 className="title mb-0">메뉴</h6>
					   <h6 className="sub ml-2 mb-0">MENU</h6>
					</div>
					<div className="modal-menu">
					   <button className="" onClick={() => {this.props.handleChange(popupView.deposit); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>입금신청</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.withdraw); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>출금신청</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.point); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>금고</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="active">
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>고객센터</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.user); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>마이페이지</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.notice); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>공지사항</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
					</div>
				 </div>
				 <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
					<div className="modal-body">
					   <div className="modal-title">
						  <h6 className="title mb-0">고객센터</h6>
						  <h6 className="sub ml-2 mb-0">HELP</h6>
					   </div>
            
             

            {RenderView()}
            {RenderWrite()}
            
             

					</div>
				 </div>
			  </div>
		   </div>
		</div>
	 </div>


   <div className="modal-backdrop fade show"></div>

          </div>

        )}
      </Popup>
    );
  }
}
