import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../../service/balance.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login"
}

interface Props {
  user: any;

  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  balance: number;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: 0 };
  }

  handleDoWithdraw = () => {

    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance) < 10000) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance).then((data) => {
      console.log(data);
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message: "보유중인 금액보다 출금신청금액이 많습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none"
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
          
          <div>


<div className="modal-app show">
		<button className="modal-close-btn modal-close" data-dismiss="modal" onClick={() => {
                    this.props.handleClose();
                  }}></button>
		<a href="/" className="diamond-logo">
		   <img className="panel" src="/new/images/panel.png"/>
		   <img className="dias-lg" src="/new/images/diamond-lg.png"/>
		   <div className="dias-sm">
			  <img className="diamond-img" src="/new/images/diamond-sm.png"/>
			  <img className="glow" src="/new/images/glow.png"/>
		   </div>
		   <img className="diamond" src="/new/images/diamond.png"/>
		   <img className="slot" src="/new/images/slot.png"/>
		</a>
		<div className="modal-menu-side">
      <button className="deposit-link" onClick={() => {this.props.handleChange(popupView.deposit); }}>
		   <i className="fas fa-coins icon" aria-hidden="true"></i>
		   <span className="text">입금신청</span>
		   </button>
		   <button className="withdraw-link" onClick={() => {this.props.handleChange(popupView.withdraw); }}>
		   <i className="fas fa-university icon" aria-hidden="true"></i>
		   <span className="text">출금신청</span>
		   </button>
		   <button className="event-link" onClick={() => {this.props.handleChange(popupView.point); }}>
		   <i className="fas fa-gem icon" aria-hidden="true"></i>
		   <span className="text">금고</span>
		   </button>
		   <button className="notice-link" onClick={() => {this.props.handleChange(popupView.help); }}>
		   <i className="fas fa-info icon" aria-hidden="true"></i>
		   <span className="text">고객센터</span>
		   </button>
		   <button className="myPage-link" onClick={() => {this.props.handleChange(popupView.user); }}>
		   <i className="fas fa-user icon" aria-hidden="true"></i>
		   <span className="text">마이페이지</span>
		   </button>
       <button className="notice-link" onClick={() => {this.props.handleChange(popupView.notice); }}>
		   <i className="fas fa-crown icon" aria-hidden="true"></i>
		   <span className="text">공지사항</span>
		   </button>
		</div>
	 </div>

	 <div className="modal loginModal subpage-modal fade show" role="dialog" data-backdrop="static" aria-modal="true" style={{paddingRight:'17px',display:'block'}}>
		<div className="modal-dialog modal-dialog-centered">
		   <div className="modal-content">
			  <div className="row">
				 <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pr-lg-5 px-3">
					<div className="modal-title">
					   <h6 className="title mb-0">메뉴</h6>
					   <h6 className="sub ml-2 mb-0">MENU</h6>
					</div>
					<div className="modal-menu">
					   <button className="" onClick={() => {this.props.handleChange(popupView.deposit); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>입금신청</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="active">
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>출금신청</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.point); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>금고</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.help); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>고객센터</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.user); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>마이페이지</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
             <button className="" onClick={() => {this.props.handleChange(popupView.notice); }}>
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>공지사항</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
					</div>
				 </div>
				 <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
					<div className="modal-body">
					   <div className="modal-title">
						  <h6 className="title mb-0">출금문의</h6>
						  <h6 className="sub ml-2 mb-0">Money Excharge</h6>
					   </div>
					   
             {/* <div className="modal-information mb-md-5 mb-4">
                <button className="mr-3">출금문의</button>
                <span>* 입금시 꼭 계좌문의를 하세요!</span>
             </div> */}
					   <p className="mb-md-5 mb-4 text-gray">
                  23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는
                  은행점검으로 인해 계좌이체가 지연될 수 있습니다.
                  <br />위 시간 이외에도 몇몇 은행은 추가적 점검시간이
                  따로 있으니 이점 유념하시기 바랍니다.
            </p>
            
            <div className="form-container">
              <div className="form-group">
                <div className="labels">
                    <span>출금금액</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    <input className="form-control parsley-success" value={this.state.balance} onChange={(e) => this.setState({balance: Number(e.target.value),})} />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="infos">
                  <div className="btn-grp">
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 30000, }); }}>3만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 50000, }); }}>5만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 100000, }); }}>10만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 500000, }); }}>50만</button>
                    <button type="button" onClick={() => { this.setState({ balance: this.state.balance + 1000000, }); }}>100만</button>
                    <button type="button" onClick={() => { this.setState({ balance: 0, }); }}>Clear</button>
                  </div>
                </div>
              </div> 

              <div className="form-group">
                <div className="labels">
                    <span>은행</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    {this.props.user.bankname}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="labels">
                    <span>출금자명</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    {this.props.user.bankowner}
                  </div>
                </div>
              </div>  

              <div className="form-group">
                <div className="labels">
                    <span>계좌번호</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    {this.props.user.banknum}
                  </div>
                </div>
              </div>                 

            </div>    

            <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={this.handleDoWithdraw}>출금신청</button>
            </div>               

					</div>
				 </div>
			  </div>
		   </div>
		</div>
	 </div>


   <div className="modal-backdrop fade show"></div>

          </div>

        )}
      </Popup>
    );
  }
}
