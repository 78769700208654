import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoodIcon from '@material-ui/icons/Mood';
import DeleteIcon from '@material-ui/icons/Delete';


import { confirmAlert } from "react-confirm-alert"; // Import
import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";
import { normalizeUnits } from "moment";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { Deposit } from "../share/deposit";
import { Withdraw } from "../share/withdraw";
import { Help } from "../share/help";
import { User } from "../share/user";
import { Notie } from "../share/notie";
import { Reg } from "../share/reg";
import { Point } from "../share/point";
import Popup from 'reactjs-popup';

import { BlueButton, GreenButton, RedButton, } from '../../utility/custom.button';

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
}

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

async function sleep(ms: any) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}


interface Props {
  classes: any;
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  isOpen: boolean;
  popupStatuses: string;
  expanded: string;
  notes: any;

}
class section extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      isOpen: false,
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: '',


    };


  }

  


  render() {
    

    return (
      
      <section className="banner-section">
        <div className="container">
               <div className="banner-carousel">
                  <div className="carousel about-carousel" data-ride="carousel" data-interval="5000" data-pause="false">
                     <div className="carousel-inner">
                        <div className="carousel-item">
                           <div className="carousel-container">
                              <div className="center text-center">
                                 <h1 className="mb-1">유럽피안&nbsp;&nbsp;꿈은&nbsp;이루어진다.</h1>
                              </div>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <div className="carousel-container">
                              <div className="center text-center">
                                 <h1 className="mb-1">유럽피안&nbsp;&nbsp;꿈은&nbsp;이루어진다.</h1>
                              </div>
                           </div>
                        </div>
                        <div className="carousel-item active">
                           <div className="carousel-container">
                              <div className="center text-center">
                                 <h1 className="mb-1">유럽피안&nbsp;&nbsp;꿈은&nbsp;이루어진다.</h1>
                              </div>
                           </div>
                        </div>
                     </div>
                     <ol className="carousel-indicators">
                        <li data-target=".about-carousel" data-slide-to="0" className=""></li>
                        <li data-target=".about-carousel" data-slide-to="1" className=""></li>
                        <li data-target=".about-carousel" data-slide-to="2" className="active"></li>
                     </ol>
                  </div>
               </div>
               <div className="jackpot-section">
                  <div className="container">
                     <div className="labels">
                        <div className="center">
                           <img className="title-img" src="/new/images/jackpot-title.png"/>
                           <img className="deco-img" src="/new/images/gold-bars.png"/>
                        </div>
                     </div>
                     <div className="amount">
                        <span id="odometer1" className="odometer odometer-theme-minimal odometer-animating-up odometer-animating">
                        </span>
                        <span data-text="원" className="text-xs">원</span>
                     </div>
                     <div className="carousel information-carousel" data-ride="carousel" data-interval="5000" data-pause="false">
                        <div className="carousel-inner">
                           <div className="carousel-item">
                              <span></span>
                           </div>
                           <div className="carousel-item active">
                              <span></span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="banner-background">
                  <img className="roulette" src="/new/images/roulette.png"/>
                  <img className="slot-girl" src="/new/images/slot-girl.png"/>
                  <div className="glow-right">
                     <img src="/new/images/glow-right.png"/>
                  </div>
                  <img className="chimney-girl" src="/new/images/chimney-girl.png"/>
                  <img className="slot-boy" src="/new/images/slot-boy.png"/>
               </div>
            </div>
      </section>

      
    );
  }
}

section.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Section = withStyles(styles, { withTheme: true })(section);
export default Section;
